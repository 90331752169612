import axios from 'axios'

let serviceUrl = ''

switch (process.env.GATSBY_ACTIVE_ENV) {
  case 'production':
    serviceUrl = process.env.GATSBY_BFF_URL ?? 'https://bff-origination-service.orquesta.tijuana.mesh-servicios-fnd.mx/v1'
    break
  case 'qa':
  case 'staging':
  case 'test':
    serviceUrl = process.env.GATSBY_BFF_URL ?? 'https://bff-origination-service.orquesta.calidad-architect.com/v1'
    break
  default:
    // serviceUrl = 'https://bfflandingmicroservice20200924113820.azurewebsites.net/v4'
    serviceUrl = process.env.GATSBY_BFF_URL ?? 'https://bff-origination-service.orquesta.calidad-architect.com/v1'
}

export const urlBFF = serviceUrl

export const LandingV4Instance = axios.create({
  baseURL: serviceUrl,
  timeout: 90000
})
